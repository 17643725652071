<template>
 <div class="full-width-table">
  <sdCards bodypadding="0px" title="Bestverkopende producten">
   <TopSellerWrap>
    <div class="table-responsive">
     <a-table :columns="productsColumns" :dataSource="productsData" :pagination="false"/>
    </div>
   </TopSellerWrap>
  </sdCards>
 </div>
</template>
<script>
import {computed, defineComponent} from 'vue';
import {TopSellerWrap} from '../../style';
import VueTypes from "vue-types";

const productsColumns = [
 {
  title: 'Naam',
  dataIndex: 'name',
  key: 'name',
 },
 {
  title: 'Getekend',
  dataIndex: 'signedCount',
  key: 'signedCount',
 },
 {
  title: 'Totaal',
  dataIndex: 'totalCount',
  key: 'totalCount',
 },
];

const TopSellingProduct = defineComponent({
 name: 'TopSellingProduct',
 components: {TopSellerWrap},
 props: {
  products: VueTypes.array.def([]),
 },
 setup(props) {
  const productsData = computed(() =>
   props.products
    ? props.products.map((value) => {
     const {name, imageFileURL, signedCount, totalCount} = value;
     return {
      key: Math.random(),
      name: (
       <div class="product-info align-center-v">
        <div class="product-img">
         <img src={imageFileURL} alt="" width="40"/>
        </div>
        <span class="product-name" style="padding-left:5px"><small>{name}</small></span>
       </div>
      ),
      signedCount: <small>{signedCount}</small>,
      totalCount: <small>{totalCount}</small>,
     };
    })
    : [],
  );

  return {
   productsColumns,
   productsData,
  };
 },
});

export default TopSellingProduct;
</script>
